@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

.brk-slid {
	overflow: hidden;
	position: relative;
}

.brk-slid-indents {
	padding: 15px;
}

.slick-loading,
.init-slider {
	height: 400px;
	overflow: hidden;
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $white;
		z-index: 998;
	}

	@keyframes loader {
		0% {
			transform: rotate(0)
		}
		100% {
			transform: rotate(360deg)
		}
	}

	&:after {
		content: '\f110';
		position: absolute;
		top: 50%;
		left: 50%;
		font-size: rem(30px);
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
		transform: translate(-50%, -50%);
		z-index: 999;
		animation: loader 1.5s infinite linear;
	}

}

.slick-list .slick-slide{
  outline: none;
}

// default-slider
.default-slider {
	width: 100%;

	.slick-list {
		padding: 15px 0 25px;
		//margin: 0 -15px;
	}

	&_mode {
		.slick-list {
			padding: 5px 0 30px;
		}
	}

	&_big {
		.slick-list {
			padding: 15px 0 40px;
		}
	}

	&_no-gutters {
		.slick-list {
			margin: 0;
		}
  }
  &_gutters-15{
    .slick-list{
      margin-left: -15px;
      margin-right: -15px;
    }
    .slick-slide{
      padding-left: 15px;
      padding-right: 15px;
    }
  }

}

// staff-slider
.staff-slider {
	position: relative;
	padding-bottom: 90px;

	&-container {
		padding-top: 10px;
		padding-bottom: 25px;
		position: relative;

		.swiper-slide {
			width: 270px;
			padding-top: 15px;
			padding-bottom: 15px;
			position: relative;

			&:before {
				content: '';
				position: absolute;
				top: 15px;
				right: 0;
				bottom: 15px;
				left: 0;
				background-color: rgba($white, .8);
        z-index: 16;
        .brk-bordered-theme & {
          border-radius: var(--b-radius);
        }
			}

			&.swiper-slide-active,
			&.swiper-slide-prev,
			&.swiper-slide-next {
				&:before {
					background-color: rgba($white, 0);
					z-index: -1;
				}
			}
		}
  }
  
  &_cornder-pagination {
    .dots-base-staff-skin {
      transform: translate(-50%, 50%);
    }
  }
}

// app slider
.brk-app-slider {
  &__img {
    @media screen and (min-width: 992px){ 
      max-width: none;
      width: 178%;
      margin-left: -32%;
    }
  }
  &__slide {
    overflow: hidden;
    padding-bottom: 30px;
  }
}

// Skin: 01 rotation-slider
.rotation-slider {
	width: 100%;
	max-width: 710px;

	img {
		width: 100%;
		height: 100%;
	}

	.slick-slide {
		position: relative;
		z-index: 7;

		.brk-slid {
			position: relative;
			transition: \transform 0.3s;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba($white, .8);
				visibility: visible;
				transition: background-color 0.2s;
				z-index: 4;
			}

			a {
				display: block;
				margin: 0 auto;
			}

		}

		&.prev-slid-index {
			.brk-slid {
				transform: translateX(20%) scale(0.85);

				@media (max-width: 575px) {
					transform: translateX(0) scale(1);
				}
			}
		}

		&.next-slid-index {
			.brk-slid {
				transform: translateX(-20%) scale(0.85);

				@media (max-width: 575px) {
					transform: translateX(0) scale(1);
				}
			}
		}

		&.slick-current,
		&.slick-center,
		&.slick-active {
			z-index: 8;

			.brk-slid {
				transform: scale(1);

				&:before {
					background-color: rgba($white, 0);
					visibility: hidden;
				}
			}
		}
  }

  // modifiers

  &_full-width {
    max-width: none;
  }
  
}

// Skin: 02 rotation-slider-min
.rotation-slider-min {
	max-width: 300px;

	img {
		width: 100%;
		height: auto;
	}

}

// Skin: 03 slider-dark
.slider-dark {
	width: 100%;

	a {
		display: block;
	}

	img {
		width: 100%;
		height: auto;
		min-height: 100%;
	}

}

// Skin: 04 slider-thumbnailed
.slider-thumbnailed {
	position: relative;

	img {
		width: 100%;
		height: auto;
		min-height: 100%;
	}

	&-for {
		width: 100%;

		a {
			display: block;
		}

	}

	&-nav {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 100%;
		max-width: 430px;
		transform: translate(-50%, 50%);
		padding: 5px 7px 5px 5px;
		background-color: $white;

		@media (max-width: 575px) {
			left: auto;
			transform: none;
			position: static;
			max-width: 100%;
		}

		.brk-slid {
			padding: 5px;
      cursor: pointer;
      &:after{
        content:'';
        display: block;
        padding-top: 100%;
      }

			&__overlay,
			&__icon {
				position: absolute;
				transition-duration: .4s;
      }

			&__overlay {
				transition-property: opacity;
			}

			&__icon {
				transition-property: \transform;
			}

      &__img{
        position: absolute;
        left: 5px;  
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
        min-height: 0;
        object-fit: cover;
        object-position: center;
      }

			&__overlay {
				top: 10px;
				right: 10px;
				bottom: 10px;
				left: 10px;
				opacity: 0;
			}

			&__icon {
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%) scale(0);
			}

			&:hover {
				.brk-slid__overlay {
					opacity: 1;
				}

				.brk-slid__icon {
					transform: translate(-50%, -50%) scale(1);
				}

			}
		}
	}

	&_shop,
	&_shop-sidebar {
		.slider-thumbnailed-for .slick-slide {
			background-color: #f3f3f3;
			border-radius: 10px;
			overflow: hidden;

			@media (max-width: 1229px) {
				height: 560px;
			}

			@media (max-width: 575px) {
				height: 490px;
			}

			img {
				position: absolute;
				width: auto;
				max-width: 100%;
				height: auto;
				max-height: 100%;
				min-height: 1px;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}

	&_shop {
		.slider-thumbnailed-for .slick-slide {
			height: 670px;
		}
	}

	&_shop-sidebar {
		.slider-thumbnailed-for .slick-slide {
			height: 480px;
		}
	}
}

// Skin: 09 tiled-slider
.tiled-slider {

	.slick-track {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.slick-track:not(:hover) {
		.slick-center {
			z-index: 5;
			transform: scale(1.3);

			.image-popup-gradient:after,
			.image-popup-gradient:before {
				opacity: 1;
			}
		}
	}

	.slick-slide {
		position: relative;
		z-index: 1;
		transform: scale(1);
		transition: \transform .4s;

		&:hover {
			z-index: 5;
			transform: scale(1.3);
		}

		.brk-slid {
			position: relative;
			padding-bottom: 100%;

			a {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;

				img {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					width: auto;
					min-width: 100%;
					max-width: none;
					height: 100%;
				}

			}

		}
	}
}

// Projects slider
.brk-carousel_project {
	.brk-carousel__item {
		position: relative;
		background-color: rgba(255, 255, 255, 0.9);
		z-index: 1;
		padding-bottom: 30px;
	}
	.brk-carousel__bg {
		padding-top: 90px;
		background-color: #fdfdfd;
		box-shadow: inset 0 -5px 20px rgba(0, 0, 0, 0.04);
	}
	.brk-carousel__img-container {
		position: relative;
		box-shadow: 0 6px 30px rgba(0, 0, 0, 0.14);
		&:after {
		content: '';
		display: block;
		padding-top: 54.7%;
		}
	}
	.brk-carousel__img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-position: center;
		object-fit: cover;
	}
	.brk-carousel__about {
		display: flex;
		padding: 33px 28px 35px 46px;
		align-items: center;
		justify-content: space-between;
		background-color: $white;
		box-shadow: 0 6px 30px rgba(0, 0, 0, 0.14);
		position: relative;
		@media screen and (max-width: 1229px){
			flex-wrap: wrap;
		}
		.btn-gradient{
			color: $white;
			text-transform: uppercase;
			display: flex;
			font-size: 14px !important;
			justify-content: center;
			align-items: center;
			.icon{
				font-size: 21px;
				position: relative;
				right: 0;
				transition: right .3s;
				margin-left: 10px;
				margin-right: -8px;
			}
			&:hover {
			color: $white;
				.icon {
					right: -5px;
				}
			}
			@media screen and (max-width: 1229px){
				width: 100%;
				margin-top: 30px;
				margin-left: 0;
				margin-right: 0 ;
			}
		}
	}
	.brk-carousel__control {
		display: flex;
		align-items: baseline;
		min-width: 140px;
		@media screen and (max-width: 1229px){
			width: 100%;
			justify-content: center;
			margin-bottom: 15px;
		}
	}
	.brk-carousel__btn-prev,.brk-carousel__btn-next{
		color: #272727;
		font-size: 24px;
		z-index: 1;
		position: relative;
		cursor: pointer;
		transition: opacity .3s, \transform .3s;
		&:hover{
			transform: scale(1.1);
		}
		&-icon {
		font-size: 40px;
		}
		&_disabled{
			pointer-events: none;
			opacity: 0.3;
		}
	}
	.brk-carousel__btn-prev {
		margin-right: 27px;
		top: -10px;
		left: 1px;
	}
	.brk-carousel__btn-next {
		margin-left: 30px;
		top: -10px;
		left: -2px;
	}
	.brk-carousel__current {
		//skin
		color: #272727;
		font-size: 52px;
		line-height: 60px;
	}
	.brk-carousel__count {
		position: relative;
		&::before {
		content: '/';
		margin-left: 5px;
		padding-left: 0;
		padding-right: 3px;
		}

	}
	.slick-slide:focus {
		outline: none;
	}
}

.triple-slider{
  .slick-slide{
    padding-left: 15px;
    padding-right: 15px;
  }
  @media screen and (min-width:991px){ 
  &.arrows-classic .slick-prev{
    left: calc(33% - 6px);
  }
  &.arrows-classic .slick-next{
    right: calc(33% - 6px);
  }
  }
  &.arrows-classic .slick-prev:hover,
  &.arrows-classic .slick-next:hover{
    background-color: var(--brand-primary);
    &::before{
      color: $white;
    }
  }
}

.brk-brand-slider{
  .slick-slide{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.brk-services-slider {
  .brk-services-slider__items{
    position: static;
  }
  .slick-list{
    margin-left: -15px;
    margin-right: -15px;
    overflow: visible;
  }
  .slick-slide{
    padding-left: 15px;
    padding-right: 15px;
    transition: opacity .3s;
  }
  .slick-slide:not(.slick-active){
    opacity: 0.1;
    pointer-events: none;
  }
  .dots-wrap-1{
    bottom: -70px;
  }
}

.brk-slider-shop {
  position: relative;
  @media screen and (max-width: 500px){ 
    margin-top: 70px;
  }

  .brk-slider__nav {

    position: absolute;
    right: 0;
    top: -70px;

    &:hover{
      button:first-child,
      button:last-child{
        box-shadow: 0 3px 10px rgba(213, 213, 213, 0.5);
        background-color: #ffffff;
      }
    }

    button {
      position: relative;

      width: 38px;
      height: 38px;

      display: inline-flex;
      align-items: center;
      justify-content: center;

      border-radius: 100%;
      margin: 0;

      box-shadow: 0 3px 10px rgba(213, 213, 213, 0.5);
      background-color: #ffffff;

			transition-property: box-shadow, background-color;
			transition-duration: .3s;

      i{
        color: #d3d3d3;
      }

      &:first-child{
        right: -10px;
      }
      &:last-child{
        z-index: 1;
        box-shadow: 0 5px 16px rgba(39, 117, 255, 0.5);
        background-color: var(--brand-primary);
      }

      &:hover:first-child,
      &:hover:last-child{
        box-shadow: 0 5px 16px rgba(39, 117, 255, 0.5);
        background-color: var(--brand-primary);

        z-index: 2;

        i{
          color: #fff;
        }
      }
    }
  }

  .slick-slide{
    padding: 30px 15px;
  }

  .slick-list{
    margin-left: -15px;
    margin-right: -15px;
  }
}

.brk-services-slider-1{
  max-width: none;
  .slick-slide{
    padding: 0 15px;
  }
  .slick-list{
    margin-left: -15px;
    margin-right: -15px;
    overflow: hidden;
  }
  [class*="dots-wrap-"]{
    transform: translate(-50%, 95px) !important;
  }
}
  

.slick-slider {
	> button {
		background-color: transparent;
	}
}
/////////////////////////////////////
//           SKINS DOTS            //
/////////////////////////////////////
.dots-base-color {
	.dots-wrap {
		color: var(--brand-primary);
	}
}

// default skin
.slick-dots {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 50%);
	padding: 0 10px;
	width: auto;
	min-width: 182px;
	max-width: 100%;
	height: 54px;
	border-radius: 27px;
	background-color: $white;
	box-shadow: 0 5px 16px rgba(0, 0, 0, .07);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9;

	li {
		position: relative;
		float: left;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		border: 1px solid transparent;
		margin: 0 5px;
		padding: 0;
		cursor: pointer;

		button {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 8px;
			height: 8px;
			border-radius: 50%;
			cursor: pointer;
			color: transparent;
			font-size: 0;
			line-height: 0;
			transition-property: width, height;
			transition-duration: .2s;
			background-color: var(--brand-primary);
		}

		&:hover,
		&.slick-active {
			border-color: #dbdbdb;

			button {
				width: 0;
				height: 0;
			}

		}
	}
}

// dots-base-skin
.dots-base-skin {
	position: relative;

	&.base-skin-top-left {
		.dots-wrap {
			bottom: auto;
			left: 0;
			top: 0;
			transform: translate(0, 0);

			@media (max-width: 991px) {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	.dots-wrap {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);
		padding: 0 10px;
		width: auto;
		min-width: 182px;
		height: 54px;
		border-radius: 27px;
		background-color: $white;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .07);
		display: flex;
		justify-content: space-around;
		align-items: center;
		z-index: 9;

		@media (max-width: 575px) {
			width: calc(100% - 30px);
		}

		.l-prev,
		.l-next {
			width: 15px;
			cursor: pointer;
			background-color: transparent;

			i {
				vertical-align: -2px;
				font-size: 22px;
			}
		}

		.l-prev {
			margin-left: 9px;
		}

		.l-next {
			margin-right: 9px;
    }
    
		.slick-dots {
			padding: 0 15px;
			position: static;
			transform: none;
			width: auto;
			min-width: 1px;
			height: auto;
			border-radius: 0;
			box-shadow: none;
			display: block;

			li {
				position: relative;
				float: left;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				border: 1px solid transparent;
				margin: 0 5px;
				padding: 0;
				cursor: pointer;

				button {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
					border-radius: 50%;
					cursor: pointer;
					color: transparent;
					font-size: 0;
					line-height: 0;
					transition-property: width, height;
					transition-duration: .2s;
				}

				&:hover,
				&.slick-active {
					border-color: #a2a2a2;

					button {
						width: 0;
						height: 0;
					}

				}

			}
    }
    
    [dir = 'rtl'] &{
      .l-prev {
        order: 3;
      }
      .l-next {
        order: 1;
      }
      .slick-dots {
        order: 2;
      }
    }
	}
}

// dots-landscape-skin
.dots-landscape-skin {
	position: relative;

	.dots-wrap {
		position: absolute;
		bottom: 46px;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 10px;
		width: auto;
		min-width: 310px;
		height: 54px;
		border-radius: 27px;
		background-color: $white;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .07);
		display: flex;
		justify-content: space-between;
		align-items: center;
		z-index: 9;

		@media (max-width: 767px) {
			min-width: 280px;
			bottom: 0;
			transform: translate(-50%, 50%);
		}

		.l-prev,
		.l-next {
			width: 15px;
			cursor: pointer;

			i {
				vertical-align: -2px;
				font-size: 22px;
			}
		}

		.l-prev {
			margin-left: 17px;
		}

		.l-next {
			margin-right: 17px;
		}

		.slick-dots {
			padding: 0 15px;
			position: static;
			transform: none;
			width: auto;
			min-width: 1px;
			height: auto;
			border-radius: 0;
			box-shadow: none;
			display: block;

			li {
				position: relative;
				float: left;
				width: 12px;
				height: 12px;
				border-radius: 50%;
				border: 1px solid transparent;
				margin: 0 5px;
				padding: 0;
				cursor: pointer;

				button {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 8px;
					height: 8px;
					border-radius: 50%;
					cursor: pointer;
					color: transparent;
					font-size: 0;
					line-height: 0;
					transition-property: width, height;
					transition-duration: .2s;
				}

				&:hover,
				&.slick-active {
					border-color: #a2a2a2;

					button {
						width: 0;
						height: 0;
					}

				}

			}
		}
	}
}

// dots-rounded-skin
.dots-rounded-skin {
	position: relative;

	.slick-dots {
		position: absolute;
		bottom: -23px;
		left: 50%;
		transform: translateX(-50%);
		padding: 0 10px;
		width: auto;
		min-width: 182px;
		max-width: 100%;
		height: 10px;
		border-radius: 0;
		background-color: transparent;
		box-shadow: none;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 9;

		li {
			position: relative;
			float: left;
			width: 26px;
			height: 9px;
			border-radius: 5px;
			border: 1px solid rgba(0, 0, 0, .13);
			margin: 0 5px;
			padding: 0;
			cursor: pointer;

			button {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 22px;
				height: 5px;
				border-radius: 3px;
				cursor: pointer;
				color: transparent;
				font-size: 0;
				line-height: 0;
				transition: opacity .2s;
				opacity: 0;
				background-image: linear-gradient(
												to right,
												rgba(var(--brand-primary-rgb), 0.92),
												rgba(var(--secondary-rgb), 0.92)
				);
			}

			&:hover,
			&.slick-active {

				button {
					opacity: 1;
				}

			}

		}
  }
  &_top-right{
    .slick-dots{
      position: absolute;
      right: -15px;
      top: -60px;
      justify-content: flex-end;
      transform: none;

			@media (max-width: 575px) {
				right: auto;
				top: -25px;
				transform: translateX(-50%);
			}
    }
  }
}

// brk-swiper-default-pagination
.brk-swiper-default-pagination {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	z-index: 99;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	bottom: 30px;

	&-bullet {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		display: inline-block;
		cursor: pointer;
		opacity: .2;
		transition: opacity .3s;
		margin: 0 5px;
		background-color: var(--brand-primary); //BASE

		&:hover,
		&-active {
			opacity: 1;
		}
	}
}

/////////////////////////////////////
//          SKINS ARROWS           //
/////////////////////////////////////

// arrows-classic
.arrows-classic {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 50px;
		height: 50px;
		padding: 0;
		transform: translateY(-50%);
		cursor: pointer;
		color: transparent;
		border: 0;
		border-radius: 50%;
		outline: none;
		z-index: 9;
		opacity: .9;
		transition: opacity .3s;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
		background-color: var(--white);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
		}

		&:hover {
			outline: none;
			opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			left: 50%;
			vertical-align: 3px;
			font-size: 22px;
			transform: translate(-50%, -50%);
			color: var(--brand-primary);
		}

	}

	.slick-prev {
		left: 10px;

		&:before {
			content: '\f104';
		}
	}

	.slick-next {
		right: 10px;

		&:before {
			content: '\f105';
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}
  }
  
  &_shop {
    .slick-prev{
      left: -25px;
    }
    .slick-next {
      right: -25px;
    }
    .slick-prev,
    .slick-next {
      top: calc(50% - 45px);
    }
  }
}

// arrows-classic-ellipse
.arrows-classic-ellipse {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 70px;
		height: 46px;
		padding: 0;
		transform: translateY(-50%);
		cursor: pointer;
		color: transparent;
		border: 0;
		border-radius: 27px;
		outline: none;
		z-index: 9;
		//opacity: .9;
		//transition: all 0.3s ease-in-out;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
		background-color: var(--white);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}

		&:hover {
			outline: none;
			//opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			left: 50%;
			vertical-align: 3px;
			font-size: 22px;
			transform: translate(-50%, -50%);
			color: var(--brand-primary);
		}

	}

	.slick-prev {
		left: -35px;

		@media (max-width: 576px) {
			left: 10px;
		}

		&:before {
			content: '\f104';
		}
	}

	.slick-next {
		right: -35px;

		@media (max-width: 576px) {
			right: 10px;
		}

		&:before {
			content: '\f105';
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}

	}
}

// arrows-classic-ellipse
.arrows-classic-ellipse-mini {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 33.2%;
		display: block;
		width: 53px;
		height: 32px;
		padding: 0;
		transform: translateY(-50%);
		cursor: pointer;
		color: transparent;
		border: 0;
		border-radius: 27px;
		outline: none;
		z-index: 9;
		//opacity: .9;
		//transition: all 0.3s ease-in-out;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
		background-color: var(--white);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
			border-radius: 50%;
		}

		&:hover {
			outline: none;
			//opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			left: 50%;
			vertical-align: 3px;
			font-size: 19px;
			transform: translate(-50%, -50%);
			color: var(--brand-primary);
		}

	}

	.slick-prev {
		left: -20px;

		@media (max-width: 576px) {
			left: 5px;
		}

		&:before {
			content: '\f104';
		}
	}

	.slick-next {
		right: -20px;

		@media (max-width: 576px) {
			right: 5px;
		}

		&:before {
			content: '\f105';
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}

	}
}

// arrows-classic-dark
.arrows-classic-dark {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 57%;
		display: block;
		width: 46px;
		height: 46px;
		padding: 0;
		cursor: pointer;
		color: transparent;
		border: 0;
		border-radius: 50%;
		outline: none;
		z-index: 9;
		opacity: .9;
		transition: opacity .3s;
		box-shadow: 0 5px 16px rgba(0, 0, 0, .12);
		background-color: var(--brand-primary);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			outline: none;
			opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			vertical-align: 3px;
			font-size: 14px;
			transform: translate(-50%, -50%);
			color: var(--white);
		}

	}

	.slick-prev {
		left: 14px;
		transform: translate(-50%, -50%);

		@media (max-width: 1240px) {
			left: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f0d9';
			left: calc(50% - 2px);
		}
	}

	.slick-next {
		right: 14px;
		transform: translate(50%, -50%);

		@media (max-width: 1240px) {
			right: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f0da';
			left: calc(50% + 2px);
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}

	}
}

// arrows-classic-dark-square
.arrows-classic-dark-square {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 50%;
		display: block;
		width: 52px;
		height: 52px;
		padding: 0;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		z-index: 9;
		opacity: .9;
		transition: opacity .3s;
		background-color: var(--brand-primary);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
		}

		&:hover {
			outline: none;
			opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			vertical-align: 3px;
			font-size: 14px;
			transform: translate(-50%, -50%);
			color: var(--white);
		}

	}

	.slick-prev {
		left: 0;
		transform: translate(-100%, -50%);

		@media (max-width: 1279px) {
			left: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f104';
			left: calc(50% - 2px);
		}
	}

	.slick-next {
		right: 0;
		transform: translate(100%, -50%);

		@media (max-width: 1279px) {
			right: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f105';
			left: calc(50% + 2px);
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}

	}
}

// arrows-classic-dark-circle
.arrows-classic-dark-circle {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 42.5%;
		display: block;
		width: 46px;
		height: 46px;
		padding: 0;
		border-radius: 50%;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		z-index: 9;
		opacity: .9;
		transition: opacity .3s;
		background-color: var(--brand-primary);

		@media (max-width: 576px) {
			width: 40px;
			height: 40px;
		}

		&:hover {
			outline: none;
			opacity: 1;
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			vertical-align: 3px;
			font-size: 19px;
			transform: translate(-50%, -50%);
			color: var(--white);
		}

	}

	.slick-prev {
		left: 0;
		transform: translate(-50%, -50%);

		@media (max-width: 1240px) {
			left: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f104';
			left: 50%;
		}
	}

	.slick-next {
		right: 0;
		transform: translate(50%, -50%);

		@media (max-width: 1240px) {
			right: 10px;
			transform: translate(0, -50%);
		}

		&:before {
			content: '\f105';
			left: 50%
		}
	}

	.slick-disabled {
		background-color: #E6E6E6 !important;
		cursor: default;

		&:before {
			color: #828282 !important;
		}

	}
}

// arrows-dark
.arrows-dark {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
		top: 0;
		bottom: 0;
		display: block;
		width: 70px;
		padding: 0;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		z-index: 9;
		background-image: none;

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
      top: 50%;
      transform: translateY(-50%);
			font-size: 45px;
			z-index: 10;
			opacity: .4;
			transition: opacity .3s;

			@media (max-width: 575px) {
				top: 50%;
				transform: translateY(-50%);
			}
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			transition: opacity .3s;
			z-index: 9;
		}

		&:hover {
			outline: none;

			&:before {
				opacity: 1;
			}
    }
    
	}

	.slick-prev {
		left: 0;

		&:before {
			content: '\f104';
			left: 30px;
		}

		&:after {
			background-image: linear-gradient(
											to right,
											rgba(0, 0, 0, 0.8),
											rgba(0, 0, 0, 0)
			);
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}

	.slick-next {
		right: 0;

		&:before {
			content: '\f105';
			right: 30px;
		}

		&:after {
			background-image: linear-gradient(
											to left,
											rgba(0, 0, 0, 0.8),
											rgba(0, 0, 0, 0)
			);
		}

		&:hover {
			&:after {
				opacity: 1;
			}
		}
	}

	.slick-prev,
	.slick-next {
		&:before {
			color: var(--white);
		}
  }
}

// arrows-modern
.arrows-modern {
	.slick-arrow {
		font-size: 0;
		line-height: 0;
		position: absolute;
    top: 50%;
    transform: translateY(-50%);
		display: block;
		width: 30px;
		height: 30px;
		padding: 0;
		cursor: pointer;
		color: transparent;
		border: 0;
		outline: none;
		z-index: 9;
		background-image: none;

		@media (max-width: 575px) {
			top: 50%;
			transform: translateY(-50%);
		}

		&:before {
			content: '';
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 600;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 45px;
			z-index: 10;
			opacity: .4;
			transition: opacity .3s;
		}

		&:hover {
			outline: none;

			&:before {
				opacity: 1;
			}
		}
	}

	.slick-prev {
		left: 23px;

		&:before {
			content: '\f104';
		}
	}

	.slick-next {
		right: 23px;

		&:before {
			content: '\f105';
		}
	}

	.slick-prev,
	.slick-next {
		&:before {
			color: var(--white);
		}
  }
  
  &_primary {
    .slick-prev,
    .slick-next {
      &:before {
        color: var(--brand-primary);
      }
    }
  }
}

.image-popup-gradient {
	position: relative;
	display: inline-block;
	z-index: 2;
	overflow: hidden;

	&:before {
		content: '\f067';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 64px;
		height: 64px;
		border-radius: 50%;
		border: 2px solid rgba(255, 255, 255, .3);
		color: $white;
		background: rgba($white, .2);
		display: flex;
		justify-content: center;
		align-items: center;
    font-family: 'Font Awesome\ 5 Free';
    font-weight: 600;
		opacity: 0;
		font-size: 23px;
		transition: opacity .4s;
		z-index: 6;

		@media (max-width: 575px) {
			width: 38px;
			height: 38px;
			font-size: 14px;
		}
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: opacity .4s;
		z-index: 4;
		background-image: linear-gradient(
										to right,
										rgba(var(--brand-primary-rgb), 0.84),
										rgba(var(--secondary-rgb), 0.84)
		);
	}

	&:hover {
		&:before {
			opacity: 1;
		}

		&:after {
			opacity: 1;
		}
	}
}


//
// dots-base-staff-skin
//
.dots-base-staff-skin {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 10px;
	width: auto;
	min-width: 182px;
	height: 54px;
	border-radius: 27px;
	background-color: #fff;
	box-shadow: 0 5px 16px rgba(0,0,0,.07);
	display: flex;
	justify-content: space-around;
	align-items: center;
	z-index: 9;

	.swiper-arrow {
		background-color: var(--white);

		i {
			color: var(--brand-primary);
		}
	}

	.button-prev,
	.button-next {
		width: 15px;
		cursor: pointer;

		i {
			vertical-align: -5px;
			font-size: rem(22);
		}
	}

	.button-prev {
		margin-left: 9px;
	}

	.button-next {
		margin-right: 9px;
	}

	.pagination {
		padding: 0 15px;
		display: block;

		li {
			position: relative;
			float: left;
			width: 12px;
			height: 12px;
			border-radius: 50%;
			border: 1px solid transparent;
			margin: 0 5px;
			padding: 0;
			cursor: pointer;
			background-color: transparent;
			opacity: 1;

			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 8px;
				height: 8px;
				border-radius: 50%;
				cursor: pointer;
				transition-property: width, height;
				transition-duration: .2s;
				background-color: var(--brand-primary);
			}

			&:hover,
			&.swiper-pagination-bullet-active {
				border-color: #dbdbdb;
				background-color: transparent;

				&:before {
					width: 0;
					height: 0;
				}
			}
		}
	}
}


//
// dots-dash-five-skin
//
.dots-dash-five-skin {
	position: absolute;
	bottom: -16px;
	left: 50%;
	transform: translateX(-50%);
	padding: 0 10px;
	width: auto;
	min-width: 240px;
	height: 46px;
	border-radius: 23px;
	background-color: var(--brand-primary);
	box-shadow: 0 5px 16px rgba(0,0,0,.1);
	display: flex;
	justify-content: space-around;
	align-items: center;
	z-index: 9;

	.button-prev,
	.button-next {
		width: 15px;
		line-height: 46px;
		cursor: pointer;
    color: #fff;
    display: flex;
    outline: none;
    
		i {
			font-size: rem(14);
		}
	}

	.pagination {
		padding: 0 15px;
		display: block;

		li {
			position: relative;
			float: left;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			border: 1px solid #fff;
			margin: 0 5px;
			padding: 0;
			cursor: pointer;
			background-color: transparent;
			opacity: 1;

			&:hover,
			&.swiper-pagination-bullet-active {
				background-color: #fff;
			}
		}
	}
}

.brk-slider-team {
  bottom: -30px;
  .slick-list {
    padding: 0;
  }
}

.brk-testimonials-dash-five{
  .dots-dash-five-skin{
    bottom: -5px;
  }
}

.slick-slide {
	> img {
		cursor: grab;

		&:active {
			cursor: grabbing;
		}
	}
}



///////////////////////////////////////////////////////
//											SWIPER											 //
///////////////////////////////////////////////////////

//
// brk-swiper-default-nav
//
.brk-swiper-default-nav {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99;
	width: 46px;
	height: 46px;
	border-radius: 50%;
	background-color: var(--brand-primary); //BASE
	box-shadow: 0 0 8px var(--brk-base-1);
	cursor: pointer;
	color: $white;
	font-size: rem(16);
	outline: none;

	i {
		vertical-align: 0;
		position: absolute;
		top: 50%;
	}

  &-prev {
		left: -23px;

		i {
			left: calc(50% - 2px);
			transform: translate(-50%, -50%);
		}
	}

	&-next {
		right: -23px;

		i {
			right: calc(50% - 2px);
			transform: translate(50%, -50%);
		}
  }

  @media screen and (max-width: 575px) {
    &-prev {
      left: 0;
    }
  
    &-next {
      right: 0;
    }
  }
}

//
// brk-swiper-default-nav-light
//
.brk-swiper-default-nav-light {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 99;
	width: 24px;
	height: 24px;
	cursor: pointer;
	color: var(--brand-primary);
	font-size: rem(36);
	line-height: 24px;

	i {
		vertical-align: 0;
		position: absolute;
		top: calc(50% - 2px);
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.brk-swiper-default-nav-prev {
		left: 14px;

	}

	&.brk-swiper-default-nav-next {
		right: 14px;
	}
}

.brk-swiper-default-modern-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	display: block;
	width: 30px;
	height: 30px;
	padding: 0;
	cursor: pointer;
	color: transparent;
	outline: none;
	z-index: 9;

	&:before {
		content: '';
		font-family: 'Font Awesome\ 5 Free';
		font-weight: 600;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 45px;
		z-index: 10;
		opacity: .4;
		transition: opacity .3s;
		color: #fff;
	}

	&.brk-swiper-default-nav-prev {
		left: 23px;

		&:before {
			content: '\f104';
		}
	}

	&.brk-swiper-default-nav-next {
		right: 23px;

		&:before {
			content: '\f105';
		}
	}
}

//
// swiper-base-arrow
//
.swiper-base-arrow {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 68px;
	height: 68px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 99;
	background-color: var(--brand-primary);
	box-shadow: 0 5px 12px var(--brk-base-1);
	outline: none;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(21);
		color: var(--white);
	}
}

.swiper-base-arrow-md {
	position: absolute;
	width: 52px;
	height: 52px;
	border-radius: 50%;
	cursor: pointer;
	z-index: 99;
	background-color: var(--brand-primary);
	box-shadow: 0 5px 12px rgba(0,0,0,.3);

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(21);
		color: var(--white);
	}

	&.button-prev,
	&.button-next {

	}
}

.swiper-base-arrow-vertical {
	position: absolute;
	width: 32px;
	height: 56px;
	cursor: pointer;
	z-index: 99;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.button-prev,
	&.button-next {

	}
}

//
// swiper-modern-arrow
//
.swiper-modern-arrow {
	position: absolute;
	top: 31px;
	right: 16px;
	width: 102px;
	height: 54px;
	border-radius: 27px;
	background-color: #fff;
	overflow: hidden;
	box-shadow: 0 5px 16px rgba(0,0,0,.07);
	z-index: 19;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: rem(24);
		color: var(--brand-primary);
	}

	.button-prev,
	.button-next {
		position: absolute;
		top: 0;
		width: 50%;
		height: 100%;
		cursor: pointer;
		outline: none;

		/*&:before,
		&:after {
			content: '';
			position: absolute;
			width: 100%;
			height: 0;
			left: 0;
			transition: height .2s;
			z-index: -1;
			background-color: var(--brk-base-4);
		}
		&:before {
			top: 0;
		}
		&:after {
			bottom: 0;
		}*/

		&:hover {
			/*&:before,
			&:after {
				height: 50%;
			}*/
			i {
				color: #222;
			}
		}

	}
	.button-prev {
		left: 0;
	}
	.button-next {
		right: 0;
	}
}

//
// Thumbnailed Slider
//
.slider-thumbnailed-full {
	position: relative;

	&-for {
		width: 100%;

		.swiper-slide {
			width: 100%;
			height: 0;
			padding-bottom: 66.66666%;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		.height-100vh & {
			.swiper-slide {
				width: 100%;
				height: 100vh;
				padding-bottom: 0;
			}
		}
	}

	&-nav {
		position: absolute;
		bottom: 55px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 700px;
		z-index: 2;

		@media (max-width: 991px) {
			max-width: 372px;
		}

		@media (max-width: 767px) {
			bottom: 15px;
			max-width: none;
		}

		@media (max-width: 575px) {
			position: static;
			transform: none;
			padding: 5px 0 0;

			.height-100vh & {
				position: absolute;
				transform: translateX(-50%);
				padding: 0 15px;
			}
		}

		.swiper-slide {
			height: 80px;
			padding: 3px;
			cursor: pointer;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background-color: rgba(#000, .5);
				transition: background-color .4s;
				z-index: 3;
			}

			&:after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				transition: opacity .4s;
				opacity: 0;
				z-index: 4;
				border: 2px solid #fff;

				@media (max-width: 575px) {
					border-color: var(--brand-primary);

					.height-100vh & {
						border-color: #fff;
					}
				}
			}



			&:hover,
			&.swiper-slide-thumb-active {
				&:before {
					background-color: rgba(#000, 0);
				}

				&:after {
					opacity: 1;
				}
			}
		}
	}

	// Arrow
	&-prev,
	&-next {
		font-size: rem(14);
		line-height: 48px;
		text-transform: uppercase;
		font-weight: 600;
		position: absolute;
		top: 50%;
		display: block;
		width: 78px;
		height: 48px;
		cursor: pointer;
		color: $white;
		text-align: center;
		z-index: 9;
		background-color: rgba(38, 38, 38, .6);
		transform-origin: center center;
		transform: translateY(-50%) rotate(-90deg);
		transition: background-color .3s;

		&:hover {
			background-color: rgba(38, 38, 38, 1);
		}
	}

	&-prev {
		left: -15px;
	}

	&-next {
		right: -15px;
	}
}

.dash-three-slider .swiper-pagination-bullet-active {
  background: var(--brand-primary);
}